import { useState, useEffect } from "react"
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { transformValue } from "@utils/transformValue";

import BannerVaptpost from "assets/png/Banner vaptpost.png";
import BannerEvent from "assets/png/BANNER EVENTO.png";
import BannerEventSmall from "assets/png/BANNER EVENTO SMALL.png";
import Banner from "assets/png/Banner ayude.png"
// import Banner from "assets/png/BANNER SITE 2.png"
import LadingCards from "components/lendingCards";
import Simulator from "components/simulator";
import TablesSimulatos from "components/tablesSimulatos";
import { colors } from "theme";
import Icons from "utils/icons";
import { CommisionTaxProps, SalesTableProps } from "types/proposals";
import RemoteServices from "service";
import { moneyMask } from "@utils/moneymask";
import useAuth from "context/auth";
import { formatCurrency } from "@utils/format";
import Breadcrumb from "components/breadcrumbs";
import useProposal from "context/proposal";
import ValueInvalid from "components/valueInvalid";
import CommissionRequest from "../commissions/request";
import Toast from "components/@ui/toast";
import { awaitTime } from "utils/awaitTime";
import { useLocation } from "react-router";
import WithLinkImagem from 'assets/png/GERAR LINK.png'
import WithoutLinkImagem from 'assets/png/PRESENCIAL.png'
import useGeneral from "context/general";
import { DialogTitle } from "@material-ui/core";
import useMediasQuerys from "hooks/mediasQuery";
import TooltipUI from "components/@ui/Tooltip";
import { Link } from "react-router-dom";

const minValue = 300
const maxValue = 5000

export const sendToShow = (id: string, placement: ScrollLogicalPosition = 'start') => {
    const element = document.getElementById(id)
    if (element) {
        element.scrollIntoView({
            block: placement ?? 'center',
            behavior: "smooth"
        });
    }
}



const Lend = () => {

    const { matchesMaxMobile, matchesOtherBanner } = useMediasQuerys()

    const {
        search
    } = useLocation()

    const {
        User,
        defaultUser
    } = useAuth();

    const {
        parcels,
        setParcels,
        cardLimit,
        setCardLimit,
        setValuesList,
        setValueProposal,
        valueProposal,
        setPreApproved,
        saleTables,
        setSaleTables,
        showForm,
        setShowForm,
        typeSimulator,
        setTypeSimulador,
        ClearProposal
    } = useProposal();

    const {
        openDialogAccount,
        setOpenDialogAccount,
        setRedirectToRequest,
        setOpenDialogCommissionRequest,
        openDialogCommissionRequest,
        account,
        setAccount
    } = useGeneral()

    const [LoadingSaleTables, setLoadingSaleTables] = useState<boolean>(false);

    const [openAlertValueInvalid, setOpenAlertValueInvalid] = useState(false)
    const [limitError, setLimitError] = useState("")
    const [loadingPreApproved, setLoadingPreApproved] = useState(false)
    const [showTypeSimulator, setShowTypeSimulator] = useState(false)


    const [allowedTables, setAllowedTables] = useState<any>([])

    const [salesTable, setSalesTable] = useState<CommisionTaxProps[]>([])



    const [availableCommission, setAvailableCommission] = useState<number>(0)

    const [proposalsIndication, setProposalsIndication] = useState([])
    const [proposals, setProposals] = useState([])
    const [modalMaintenance, setModalMaintenance] = useState(false)

    const [error, setError] = useState(false)

    const getAvailableCommissions = () => {
        RemoteServices.commissions.Available()
            .then(({
                response
            }) => {
                if (response?.amount) {
                    setAvailableCommission(response.amount)
                }
            })
    }

    useEffect(() => {
        getAvailableCommissions()
        if (search.slice(1, search.length) === 'noreload') {
            return
        }
        ClearProposal()
    }, [])

    const getProposals = async () => {
        RemoteServices.commissions.ProposalsComnisssions().then(({ response }) => {
            setProposals(response)
        }).catch((err) => Toast({ type: 'error' }, 'Erro ao buscar propostas'))

        RemoteServices.commissions.ProposalsComnisssions(true).then(({ response }) => {
            setProposalsIndication(response)
        }).catch((err) => Toast({ type: 'error' }, 'Erro ao buscar propostas de indicações'))
    }

    const getAccount = async () => {
        RemoteServices.commissions.getAccount().then(({ response }) => {
            if (response?.result?.account === null) {
                setOpenDialogAccount(true)
                setRedirectToRequest(true)
            } else {
                setAccount(response?.result?.account)
                setOpenDialogCommissionRequest(true)
                getProposals()
            }
        })
    }

    useEffect(() => {
        if (User) {
            const array: number[] | string[] = ["0"]
            for (let value = minValue; value <= maxValue; value += 10) {
                array.push(formatCurrency(value))
            }
            setValuesList(array)
            getTables()
            getAllowedTables()
        }
    }, [User])


    useEffect(() => {
        if (parcels && (valueProposal && valueProposal !== "0") && typeSimulator === 'perValue') {
            getLinks(Number(parcels), transformValue(valueProposal))
            console.log('1')
        } else if (parcels && typeSimulator === 'perTable') {
            getMaxSaleTablesPerLimit()
        }
    }, [parcels, valueProposal, typeSimulator])


    const getMaxSaleTablesPerLimit = async () => {
        setLoadingSaleTables(true)
        // setSaleTables([])
        const filter = salesTable?.filter((item) => {
            if (item.parcels === Number(parcels)) return item
        })

        const tables: any[] = []

        if (!filter) {
            return
        }

        for (let _f of filter) {
            let valuePerTable = (transformValue(cardLimit) * 0.97) / (1 + _f.fees_tax)
            let valueFormated
            if (valuePerTable < maxValue) {
                const redonlyValue = valuePerTable % 10
                if (redonlyValue === 0) {
                    valueFormated = valuePerTable
                } else {
                    valueFormated = valuePerTable - redonlyValue
                }
            } else {
                valueFormated = maxValue
            }
            if (valueFormated >= minValue) {
                await RemoteServices.proposals.GetLinks({
                    parcels: Number(parcels),
                    net_amount: valueFormated,
                    sale_table: _f.sale_table
                }).then(({ response }) => {
                    if (response?.saleTables[0]) {
                        tables.push(response.saleTables[0])
                    }
                }).catch((err) => {
                    setError(true)
                    return
                })
            }
        }
        setLoadingSaleTables(false)
        console.log(tables)
        if (tables?.length > 0) {
            setSaleTables(tables)
        }
    }


    const getTables = async () => {
        RemoteServices.proposals.TablesOWner().then(({ response }) => {
            const filter = response?.result.filter((item: CommisionTaxProps) => {
                if (Number(item.sale_table.split("on-")[1]) <= 15) {
                    return item
                }
            })
            setSalesTable(filter)
        })
    }




    const getAllowedTables = async () => {
        RemoteServices.proposals.SalesTable().then(({ response }) => {
            if (response?.result) {
                setAllowedTables(response.result)
            }
        })
    }




    const calcPreApproved = () => {
        const restt = transformValue(cardLimit).toString().split('.').length === 1
        if (restt) {
            setOpenAlertValueInvalid(true)
            return
        }
        setLoadingPreApproved(true)

        const lowerRate = salesTable?.reduce((prev, current) => {
            return prev.fees_tax < current.fees_tax ? prev : current
        })
        const lower12x = salesTable?.find((item) => item.sale_table === lowerRate.sale_table && item.parcels === 12)
        let valuePreApproved = (transformValue(cardLimit) * 0.97) / (1 + (lower12x?.fees_tax ?? 0))
        let finalPreApproved = 0
        let lower12byThousand = salesTable?.find((item) => item.sale_table === 'on-15' && item.parcels === 12)
        valuePreApproved = (transformValue(cardLimit) * 0.97) / (1 + (lower12byThousand?.fees_tax ?? 0))
        console.log(valuePreApproved)
        if (valuePreApproved < minValue) {
            setLimitError(`Saldo insuficiente para prosseguir com a operação. Oriente o cliente a retornar quando o saldo disponível em seu cartão for acima de R$ 372,00`)
            setShowForm(false)
            setLoadingPreApproved(false)
            return
        } else {
            if (valuePreApproved < maxValue) {
                const redonlyValue = valuePreApproved % 10
                if (redonlyValue === 0) {
                    finalPreApproved = valuePreApproved
                } else {
                    finalPreApproved = valuePreApproved - redonlyValue
                }
            } else {
                finalPreApproved = maxValue
            }
        }

        setPreApproved(finalPreApproved)
        setLimitError("")
        if (typeSimulator === "perValue") {
            setValueProposal(moneyMask(minValue.toString() + '00'))
        }
        setTimeout(() => {
            setShowForm(true)
            setLoadingPreApproved(false)

        }, 500)
    }

    async function getLinks(parcels: number, valueProposal: number) {
        setLoadingSaleTables(true)
        // setSaleTables([])
        RemoteServices.proposals.GetLinks({ parcels, net_amount: valueProposal }).then(({ response }) => {
            if (response?.saleTables) {
                console.log(response.saleTables)
                const firstFilter = response.saleTables.filter((item: SalesTableProps) => Number(item.sale_table.replace('on-', '')) <= 15)
                const secondFilter = firstFilter.filter((item: SalesTableProps) => item.gross_amount <= transformValue(cardLimit))
                console.log(transformValue(cardLimit))
                setSaleTables(secondFilter)
            }
        }).catch(async (e) => {
            await awaitTime(3)
            Toast({
                type: 'error'
            }, 'Falha ao buscar links')
            return getLinks(parcels, valueProposal)

        }).finally(() => {
            setLoadingSaleTables(false)
        })
    }


    useEffect(() => {
        if (showForm) {
            sendToShow("parcels", "start")
            if (saleTables?.length > 0) {
                sendToShow("tables-on", "start")
            }
            if (LoadingSaleTables) {
                sendToShow("tables-on-loading", "start")
            }
        }
    }, [showForm, saleTables, LoadingSaleTables])


    return (
        <>
            <Grid
                xs={12}>
                {/* <TooltipUI title="Clique no Banner para acessar nosso grupo de WhatsApp e participar de nosso treinamento gratuito!" followCursor>
                    <Link to={"https://chat.whatsapp.com/F5s8xvMj7Tj1JpSVCzY6kz"} target="_blank">
                        <img
                            src={matchesOtherBanner ? BannerEventSmall : BannerEvent}
                            style={{ maxHeight: '100%', padding: matchesMaxMobile ? 0 : '0px 10px', cursor: 'pointer' }}
                            width={"100%"}
                            alt="BannerEvent" />
                    </Link>
                </TooltipUI> */}
                <img
                    src={Banner}
                    style={{ maxHeight: '100%', padding: matchesMaxMobile ? 0 : '0px 10px', cursor: 'pointer' }}
                    width={"100%"}
                    alt="BannerEvent" />
                <Grid
                    px={2}
                    mt={2}
                >
                    <Link to={"https://youtu.be/2H0zpQmoDZw"} target="_blank">
                        <img
                            style={{
                                maxHeight: '150px',
                                width: 'auto',
                                borderRadius: '20px',
                                cursor: 'pointer'
                            }}
                            src={BannerVaptpost}
                            alt="BannerVaptpost"
                        />
                    </Link>

                </Grid>
                <Grid
                    xs={12}
                    mt={2}
                    p={2}>
                    {
                        !matchesMaxMobile &&
                        <Typography
                            textTransform={"uppercase"}
                            color={colors.grey400}
                            fontWeight={"bold"}>
                            <Breadcrumb />
                        </Typography>
                    }

                    <Grid px={2}>
                        <Grid
                            container
                            gap={2}
                            py={2}
                            justifyContent={"space-between"}
                            alignItems={"flex-end"}
                            mt={0}
                            mb={8}
                        >
                            <Grid
                                item
                                gap={2}
                                py={2}
                                container
                                flexDirection={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                            >
                                {
                                    !matchesMaxMobile &&
                                    <Grid maxWidth={295} container gap={2} flexDirection={"row"}>
                                        <img
                                            src={Icons.IconEmprestimo}
                                            alt="icon"
                                            width={30}
                                            style={{ filter: 'brightness(0)' }}
                                        />
                                        <Typography
                                            textTransform={"uppercase"}
                                            color={colors.black}
                                            variant="h5"
                                            fontWeight={"bold"}>Emprestar <u>agora</u></Typography>
                                    </Grid>
                                }
                                {/* <Grid>
                                    <Grid
                                        gap={2}
                                        py={2}
                                        container
                                        direction={"row"}

                                    >
                                        <a target="_blank" href="/app/emprestar/tutorial?id=2" >
                                            <img
                                                src={WithoutLinkImagem}
                                                style={{
                                                    width: '250px',
                                                    height: '130px',
                                                    cursor: 'pointer'
                                                }} />
                                        </a>
                                    </Grid>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid mt={-4} container flexDirection={"column"} xs={12} gap={4}>
                            {User?.space !== "gestor" && <LadingCards
                                onClickCommissionRequest={() => getAccount()}
                            />}
                            {User?.space === "gestor" && defaultUser?.permissions?.permissions.includes("readCommission") && <LadingCards
                                onClickCommissionRequest={() => getAccount()}
                            />}
                            <Simulator
                                calcPreApproved={calcPreApproved}
                                limitError={limitError}
                                loadingPreApproved={loadingPreApproved}
                                onChangeText={(e) => {
                                    if (transformValue(e.target.value) < 1000000) {
                                        setCardLimit(e.target.value !== "" ? e.target.value : '0')
                                    } else {
                                        setCardLimit(moneyMask("99999999"))
                                    }
                                    setParcels(null)
                                    setSaleTables([])
                                    setValueProposal('')
                                    setShowTypeSimulator(false)
                                    setShowForm(false)
                                    setTypeSimulador('perTable')
                                }} />
                            {
                                showForm &&
                                <TablesSimulatos
                                    LoadingSaleTables={LoadingSaleTables}
                                    typeSimulator={typeSimulator}
                                    changeParcel={(parcelSelect) => {
                                        setParcels(parcelSelect !== parcels ? parcelSelect : null)
                                        setSaleTables([])
                                    }}
                                    changeTypeSimulador={(type) => {
                                        setTypeSimulador(type);
                                        setSaleTables([])
                                        // setParcels(0)
                                    }}
                                    parcels={parcels}
                                    showTablesSimulator={true}
                                    saleTables={saleTables}
                                    error={error} />
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <ValueInvalid
                    openAlertValueInvalid={openAlertValueInvalid}
                    onClose={() => {
                        setOpenAlertValueInvalid(false)
                    }}
                />
                <CommissionRequest
                    open={openDialogCommissionRequest}
                    getAccount={getAccount}
                    commissionValue={availableCommission}
                    handleClose={() => setOpenDialogCommissionRequest(false)}
                    getProposals={getProposals}
                    getAvailableCommissions={getAvailableCommissions}

                />
            </Grid>
            <Dialog open={modalMaintenance}>
                <DialogTitle>
                    <Typography variant="h4" textAlign={"center"}>Manutenção</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography padding={"20px 30px"}>Prezado cliente, neste momento o banco está em manutenção, já estamos trabalhando para voltar à normalidade o mais breve possível.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setModalMaintenance(false)}>Entendi</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Lend;